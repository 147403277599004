import React from 'react'
import ScrollTo from 'src/components/ScrollTo'

import { Section  } from './style'

const Hero = () => {

  return (
    <Section className='py-5 d-flex' role='img' aria-label='Uma mulher sorridente mostrando o Super App do Inter para um homem. Ao fundo está um prédio.'>
      <div className='container d-flex align-items-center'>
        <div className='row'>
          <div className='col-12 col-md-6 col-lg-5 offset-md-6 offset-lg-7 content px-5 px-md-0 py-5'>
            <h1 className='fs-28 fs-lg-40 fs-xl-56 lh-33 lh-lg-45 lh-xl-60 text-white fw-500 mb-3'>
              Serviços bancários para uma vida financeira mais inteligente.
            </h1>
            <p className='fs-16 fs-lg-18 lh-20 lh-lg-22 text-white fw-400'>Encontre serviços bancários fáceis de usar no Inter. Da Conta Digital ao crédito, do Pix ao investimento: conheça produtos que vão simplificar seu dia a dia.</p>
            <ScrollTo
               styles='fs-14 fw-600 btn btn--lg btn-white text-none rounded-3 mt-3 mt-md-0'
               to='#digital-account'
               section='dobra_01'
               sectionName='Serviços bancários para uma vida financeira mais inteligente.'
               elementName='Saber mais'
               title='Saber mais'
             >
               Saber mais
             </ScrollTo>
         </div>
        </div>
      </div>
    </Section>
  )
}

export default Hero
