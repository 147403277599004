import React from 'react'
import Link from 'src/components/GatsbyLinkWrapper/index'
import ImageWebp from 'src/components/ImageWebp'
import useDataLayer from 'src/hooks/useDataLayer/dataLayerBody'
import useWidth from 'src/hooks/window/useWidth'
import { WIDTH_MD } from 'src/utils/breakpoints'

const PlatinumContent = () => {
  const [ sendDatalayerEvent ] = useDataLayer()
  const width = useWidth()

    return (
      <>
        <div className='d-md-flex flex-lg-column col-lg-6 pl-lg-0'>
          <div className='col-12 col-md-6 col-lg-12'>
            <div className='d-flex justify-content-center mb-3 mb-md-0'>
              { width < WIDTH_MD || width > WIDTH_MD ? <ImageWebp
                pathSrc='https://central-imagens.bancointer.com.br/images-without-small-versions/banking-dobra5-platinum/image.webp'
                altDescription='Ilustração do cartão Platinum Inter.'
                arrayNumbers={[268, 261, 400, 400]}
              /> : <ImageWebp
                pathSrc='https://central-imagens.bancointer.com.br/images-without-small-versions/dobra6-banking-black/image.webp'
                altDescription='Ilustração do cartão Platinum Inter.'
                arrayNumbers={[268, 261, 400, 400]}
              />}
            </div> 
          </div> 
          <div className='col-12 col-md-6 col-lg-12 px-lg-4 mt-lg-3'>
            <h2 className='fs-24 fs-md-28 fs-xl-48 lh-28 lh-md-34 lh-xl-52 text-grayscale--500 fw-500'>Cartão Platinum Inter.</h2>
            <h3 className='fs-20 fs-lg-24 lh-24 lh-lg-28 lh-xl-34 mt-3 text-grayscale--500'>Mais benefícios pra você.</h3>
            <p className='fs-16 fs-lg-18 lh-20 lh-lg-22 text-grayscale--400 mt-md-4'>Pontue no Loop a cada R$ 5 em compras no Cartão Platinum, planeje-se com um concierge de viagens, acesse ofertas especiais e mais.</p>
            <Link
              title='Conheça o Cartão Platinum'
              className='btn btn--lg rounded-2 fs-10 fw-700 text-none btn-orange--extra mt-2'
              to='/pra-voce/cartoes/cartao-platinum/'
              target='blank'
              onClick={() => {
                sendDatalayerEvent({
                  section: 'dobra_05',
                  element_action: 'click button',
                  element_name: 'Conheça o Cartão Platinum',
                  section_name: 'Cartão Platinum Inter.',
                  redirect_url: `${window.location.origin}/pra-voce/cartoes/cartao-platinum/`
                })
              }}
            >
              Conheça o Cartão Platinum
            </Link>
          </div>
        </div>
      </>
    )
  }

  export default PlatinumContent
  
