import styled from 'styled-components'
import { breakpoints } from 'src/styles/breakpoints'
import { orange, primary } from 'src/styles/newColors'

export const Section = styled.section`

  @media (min-width: ${breakpoints.md}){
    background-color: ${orange.dark};
  }

  a {
    outline: none!important;
    border: none!important;
    color: ${primary[500]};
  }
  
  .content {
    background-color: ${primary[500]};
    border-radius: 130px 60px 130px 60px;
    padding: 20px 0 0 0;

    @media (min-width: ${breakpoints.md}){
      background-color: transparent;
      border-radius: none;
    }
  }
  
  @media (min-width: ${breakpoints.md}){
    background-image: url('https://central-imagens.bancointer.com.br/images-without-small-versions/banking-dobra1/image.webp');
    background-color: ${primary[500]};
    height: 353px;
    background-position: -46px 0px;
    background-repeat: no-repeat;
    background-size: contain;

    a {
      max-width: 336px;
    }
  }

  @media (min-width: ${breakpoints.lg}){
    height: 456px;
    background-position: top left;

    a {
      max-width: 376px;
    }
  }

  @media (min-width: ${breakpoints.xl}){
    height: 609px;
    background-size: contain;

    a {
      max-width: 454px;
    }
  }
`
