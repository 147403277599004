import styled from 'styled-components'
import { breakpoints } from 'src/styles/breakpoints'

export const Section = styled.section`
    
  @media (min-width: ${breakpoints.md}){
    background-image: url('https://central-imagens.bancointer.com.br/images-without-small-versions/banking-dobra11/image.webp');
    background-size: contain;
    background-position: -50px 0;
    background-repeat: no-repeat;
    height: 329px;
  }
  
  @media (min-width: ${breakpoints.lg}){
    background-position: top left;
    height: 348px;
    background-size: contain;
  }

  @media (min-width: ${breakpoints.xl}){
    background-position: top left;
    min-height: 535px;
  }

  a {
    @media (min-width: ${breakpoints.md}){
      max-width: 336px;
    }
    @media (min-width: ${breakpoints.lg}){
      max-width: 373px;
    }
    @media (min-width: ${breakpoints.xl}){
      max-width: 454px;
    }
  }
`
