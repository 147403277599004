import React from 'react'
import useDataLayer from 'src/hooks/useDataLayer/dataLayerBody'
import ImageWebp from 'src/components/ImageWebp'
import Link from 'src/components/GatsbyLinkWrapper/index'
import useWidth from 'src/hooks/window/useWidth'
import { WIDTH_MD } from 'src/utils/breakpoints'

import { Section } from './style'

const Overdraft = () => {
  const [ sendDatalayerEvent ] = useDataLayer()
  const width = useWidth()

  return (
    <Section id='digital-accoun' className='py-5'>
      <div className='container'>
        <div className='row d-flex align-items-center justify-content-center justify-content-xl-between'>
          <div className='col-12 col-md-6 col-lg-5 offset-lg-2 order-md-last'>
            <div className='d-flex justify-content-center justify-content-lg-end mb-3 mb-md-0'>
            {width < WIDTH_MD ?
              <ImageWebp
                pathSrc='https://central-imagens.bancointer.com.br/images-without-small-versions/dobra11-mobile-banking/image.webp'
                altDescription='Cliente do Inter acessando sua conta digital do Inter.'
                arrayNumbers={[316, 344, 379, 452]}
              /> :
                <ImageWebp
                  pathSrc='https://central-imagens.bancointer.com.br/images-without-small-versions/banking-dobra10/image.webp'
                  altDescription='Cliente do Inter acessando sua conta digital do Inter.'
                  arrayNumbers={[316, 344, 379, 452]}
                />}
            </div> 
          </div> 
          <div className='col-12 col-md-6 col-lg-5'>
            <h2 className='fs-24 fs-md-28 fs-xl-48 lh-28 lh-md-34 lh-xl-52 text-grayscale--500 fw-500'>Cheque Especial: se precisar, o Inter tem</h2>
            <p className='fs-16 fs-lg-18 lh-20 lh-lg-22 text-grayscale--400 mt-md-4'>Conte com o Cheque Especial do Inter para momentos de aperto: um limite extra disponível para você fazer transações mesmo sem saldo na conta.</p>
            <Link
              title='Quero saber mais '
              className='btn btn--lg rounded-2 fs-10 fw-700 text-none btn-orange--extra mt-2 mt-md-3'
              to='/pra-voce/emprestimos/cheque-especial/'
              target='blank'
              onClick={() => {
                sendDatalayerEvent({
                  section: 'dobra_10',
                  element_action: 'click button',
                  element_name: 'Quero saber mais',
                  section_name: 'Cheque Especial: se precisar, o Inter tem',
                  redirect_url: `${window.location.origin}/pra-voce/emprestimos/cheque-especial/`
                })
              }}
            >
              Quero saber mais 
            </Link>
          </div>
        </div>  
      </div>
    </Section>
  )
}

export default Overdraft
