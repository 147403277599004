import styled from 'styled-components'
import { breakpoints } from 'src/styles/breakpoints'
import { white } from 'src/styles/colors'

export const Section = styled.section`
  .bg {
   @media (min-width: ${breakpoints.md}) { 
      background-color: ${white};
    }
  }  

  @media (min-width: ${breakpoints.md}){ 
    background-color: transparent;
  }
  
  @media (min-width: ${breakpoints.md}){
    background-image: url('https://central-imagens.bancointer.com.br/images-without-small-versions/banking-dobra3/image.webp');
    background-size: contain;
    background-position: top center;
    background-repeat: no-repeat;
    height: 374px;
  }

  @media (min-width: ${breakpoints.lg}){
    height: 444px;
    background-size: cover;
  }

  @media (min-width: ${breakpoints.xl}){
    height: 670px;
  }

  @media (min-width: ${breakpoints.xxxl}){
    height: 940px;
    background-size: cover;
  }

  a {
    @media (min-width: ${breakpoints.md}){
      max-width: 100%;
    }
  }
`
