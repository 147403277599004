import React from 'react'
import Layout from 'src/layouts/BaseLayout'

import pageContext from './pageContext.json'

import Hero from './sections/hero/_index'
import DigitalAccount from './sections/digital-account/_index'
import PixOnInter from './sections/pix-on-inter/_index'
import CreditCard from './sections/credit-card/_index'
import InterCard from './sections/inter-card/_index'
import LimitIncrease from './sections/limit-increase/_index'
import PayByContact from './sections/pay-by-contact/_index'
import Renegotiate from './sections/renegotiate/_index'
import SalaryPortability from './sections/salary-portability/_index'
import Overdraft from './sections/overdraft/_index'
import OpenFinancy from './sections/open-financy/_index'

import { Wrapper } from './style'

const Banking = () => {

  return (
    <Wrapper>
      <Layout pageContext={pageContext}>
        <Hero />
        <DigitalAccount />
        <PixOnInter />
        <CreditCard />
        <InterCard />
        <LimitIncrease />
        <PayByContact />
        <Renegotiate />
        <SalaryPortability />
        <Overdraft />
        <OpenFinancy />
      </Layout>
    </Wrapper>
  )
}

export default Banking
