import React from 'react'
import useWidth from 'src/hooks/window/useWidth'
import { WIDTH_LG } from 'src/utils/breakpoints'
import BlackContent from './BlackContent/_index'
import PlatinumContent from './PlatinumContent/_index'

import { Section } from './style'

const InterCard = () => {
  const width = useWidth()

  return (
    <Section id='digital-accoun'>
      <>
       {width < WIDTH_LG ? 
        <>
          <div className='bg-card-platinum py-5'>
            <div className='container'>
              <div className='row d-flex align-items-center justify-content-center'>
                <PlatinumContent />
              </div>
            </div>
          </div>
            <div className='bg-credit-card py-5'>
              <div className='container'>
                <div className='row d-flex align-items-center justify-content-center'>
                  <BlackContent />
                </div>
              </div>
            </div>
          </> : 
          <div className='bg py-5'>
          <div className='container'>
            <div className='row d-flex align-items-center justify-content-center'>
              <PlatinumContent />
              <BlackContent />
            </div> 
          </div>
        </div>}
      </>
    </Section>
  )
}

export default InterCard
